export type EndPoints = {
  deleteApiEndpoint: string;
  fetchApiEndpoint: string;
  viewEndPoint: string;
};

export enum UserRole {
  ADMIN = "admin",
  BASIC = "basic",
  PREMIUM = "premium",
}

export type GenericResponse = {
  data?: any;
  message: string;
};

export enum RecordTypes {
  TARGET_WORDS = "targetWords",
  USERS = "users",
}

export type RecordType = RecordTypes.TARGET_WORDS | RecordTypes.USERS;

export enum MessageType {
  WARNING = "warning",
  ERROR = "error",
  SUCCESS = "success",
  INFO = "info",
}

export type MessageTypes =
  | MessageType.WARNING
  | MessageType.ERROR
  | MessageType.SUCCESS
  | MessageType.INFO;

export type TargetWordReponse = {
  blend: string;
  blendDirection: {
    blendDirection: string;
  };
  id: number;
  phoneme: {
    phoneme: string;
  };
  position: {
    position: string;
  };
  targetSound: {
    targetSound: string;
  };
  word: string;
};

export const API_ENDPOINT_STUDENT_SESSION_CREATE =
  "/api/student-session/create";

export const API_ENDPOINT_TARGET_WORDS_BY_ID = "/api/target-words/by-id";
export const API_ENDPOINT_TARGET_WORDS_DELETE = "/api/target-words/delete";
export const API_ENDPOINT_TARGET_WORDS_PAGINATE = "/api/target-words/paginate";

export const API_ENDPOINT_USER_ROLE_ID = "/api/user-roles/by-id";

export const API_ENDPOINT_USER_CREATE = "/api/users/create";
export const API_ENDPOINT_USER_DETAILS = "/api/users/details";
export const API_ENDPOINT_USER_UPDATE = "/api/users/update";

export const API_ENDPOINT_STUDENT_CREATE = "/api/students/create";
export const API_ENDPOINT_STUDENT_DETAILS = "/api/students/details";
export const API_ENDPOINT_STUDENTS_BY_TEACHER_ID =
  "/api/students/all-by-teacherid";

export const GENERIC_ERROR_MESSAGE =
  "An error occurred. We have been notified and will attend to this issue shortly. Please try again later.";

export const STUDENT_CREATE_ERROR_MESSAGE =
  "Error creating new user. We have been notified and will attend to this issue shortly. Please try again later.";
export const STUDENTS_FOR_TEACHER_ERROR_MESSAGE =
  "Error getting students for teacher. We have been notified and will attend to this issue shortly. Please try again later.";
export const STUDENTS_FOR_TEACHER = "Students for teacher found.";
export const STUDENTS_FOR_TEACHER_NOT_FOUND = "No students found for teacher.";
export const STUDENT_CREATE_SUCCESS =
  "Your new student details have been saved successfully.";

export const USER_ROLEID_ERROR_MESSAGE =
  "Error getting role id. We have been notified and will attend to this issue shortly. Please try again later.";
export const USER_CREATE_ERROR_MESSAGE =
  "Error creating new user. We have been notified and will attend to this issue shortly. Please try again later.";
export const USER_LOAD_ERROR_MESSAGE =
  "Error loading user details. We have been notified and will attend to this issue shortly. Please try again later.";
export const USER_UPDATE_ERROR_MESSAGE =
  "Error updating user details. We have been notified and will attend to this issue shortly. Please try again later.";
export const USER_UPDATE_SUCCESS =
  "Your profile information has been updated successfully.";
export const USER_NOT_VERIFIED_MESSAGE =
  "Please check your email. An message was sent to the address used during sign up. Please follow the instructions to verify your account.";

export const AVATAR_FALLBACK_URL = "/static/person.svg";

export const modeUIConstants = {
  blend: {
    value: "blend",
    displayName: "Blend",
  },
  position: {
    value: "position",
    displayName: "Position",
  },
};

export const positionUIConstants = {
  initial: {
    value: "initial",
    displayName: "Initial",
  },
  medial: {
    value: "medial",
    displayName: "Medial",
  },
  final: {
    value: "final",
    displayName: "Final",
  },
};

export const visualStyleUIConstants = {
  list: {
    value: "list",
    displayName: "List",
  },
};
